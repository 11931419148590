import { RouterProvider } from 'react-router-dom';

import type { User } from 'oidc-client-ts';
import { ErrorBoundary } from './components/ErrorBoundary';
import { InvalidLogin } from './components/error/InvalidLogin';
import { router } from './routes/Router';
import { isAzureLogin, isHelpGangRoute, isRioPlatformLogin } from './configuration/setup/helpgangDetector';
import ErrorFallback from './components/error/ErrorFallback';
import { DEFAULT_LOCALE } from './configuration/lang/lang';
import { useAppSelector } from './configuration/setup/hooks';
import { getDisplayMessages, getLocale } from './configuration/lang/langSlice';
import { IntlProvider } from 'react-intl';

export const App = ({ user }: { user: User | null }) => {
    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);

    if (!(displayMessages && userLocale)) {
        return null;
    }

    try {
        if (isHelpGangRoute()) {
            if (isRioPlatformLogin((user as User).profile.sub)) {
                return <InvalidLogin idToken={user?.id_token} loginType={'rio'} />;
            }
        } else {
            if (isAzureLogin((user as User).profile.sub)) {
                return <InvalidLogin idToken={user?.id_token} loginType={'azure'} />;
            }
        }
    } catch (e) {
        console.error(e);
    }

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <ErrorBoundary fallback={<ErrorFallback />}>
                <RouterProvider router={router} />
            </ErrorBoundary>
        </IntlProvider>
    );
};
