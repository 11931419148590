import * as api from './generated/rental-api';
import type { StartRentalCommand, ZurueckDoppelCommand } from '../../domain/model/commands.types';
import type { RentalCompany, RenterPartnerCompany } from '../../domain/model/rental-companies.types';
import type { Rental } from '../../domain/model/rentals.types';

export const mapStartRentalCommandToApi = (command: StartRentalCommand): api.StartRentalCommand =>
    api.StartRentalCommand.parse({
        rental_company_asset_id: command.rentalCompanyAssetId,
        renter_account_id: command.renterCompanyAccountId,
    });

export const mapStopRentalCommandToApi = (command: { rentalId: string }): api.StopRentalCommand =>
    api.StopRentalCommand.parse({
        rental_id: command.rentalId,
    });

export const mapSellVehicleCommandToApi = (command: { rentalId: string }): api.StopAndSellCommand =>
    api.StopAndSellCommand.parse({
        rental_id: command.rentalId,
    });

export const mapZurueckDoppelCommandToApi = (command: ZurueckDoppelCommand): api.ZurueckDoppelCommand =>
    api.ZurueckDoppelCommand.parse({
        rental_company_asset_id: command.rentalCompanyAssetId,
        renter_account_id: command.renterCompanyAccountId,
    });

export const mapRentalCompanyToDomain = (response: api.RentalCompany): RentalCompany => {
    return {
        accountId: response.id,
        contractType: mapContractTypeToDomain(response.contract_type),
    };
};

const mapContractTypeToDomain = (contractType: api.ContractType): RentalCompany['contractType'] => {
    switch (contractType) {
        case 'ACCOUNT_WIDE':
            return 'ACCOUNT_WIDE';
        case 'ON_DEMAND':
            return 'ON_DEMAND';
        case 'INACTIVE':
            return 'INACTIVE';
        default:
            throw new Error(`Unexpected contract type: ${contractType}`);
    }
};

const mapStateTypeToDomain = (state: api.State): Rental['state'] => {
    switch (state) {
        case 'STARTED':
            return 'STARTED';
        case 'STOPPED':
            return 'STOPPED';
        case 'SOLD':
            return 'SOLD';
        default:
            throw new Error(`Unexpected state type: ${state}`);
    }
};

export const mapRentalToDomain = (rental: api.Rental): Rental => {
    return {
        id: rental.id,
        state: mapStateTypeToDomain(rental.state),
        rentalCompanyAssetId: rental.rental_company_asset_id,
        rentalCompanyAccountId: rental.rental_company_account_id,
        renterAssetId: rental.renter_asset_id,
        renterAccountId: rental.renter_account_id,
        // biome-ignore lint/style/noNonNullAssertion: see below
        startedAt: rental.started_at!, // TODO RIOTT-7296 investigate if this is nullable or not
    };
};

export const mapRenterPartnerCompanyToDomain = (response: api.RenterPartnerCompany): RenterPartnerCompany => {
    return {
        accountId: response.id,
        name: response.name,
    };
};
