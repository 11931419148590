import { z } from "zod";

export type RentalId = z.infer<typeof RentalId>;
export const RentalId = z.string();

export type State = z.infer<typeof State>;
export const State = z.string();

export type RentalCompanyAssetId = z.infer<typeof RentalCompanyAssetId>;
export const RentalCompanyAssetId = z.string();

export type RentalCompanyAccountId = z.infer<typeof RentalCompanyAccountId>;
export const RentalCompanyAccountId = z.string();

export type RenterAssetId = z.infer<typeof RenterAssetId>;
export const RenterAssetId = z.string();

export type RenterPartnerAccountId = z.infer<typeof RenterPartnerAccountId>;
export const RenterPartnerAccountId = z.string();

export type RentalStartedAt = z.infer<typeof RentalStartedAt>;
export const RentalStartedAt = z.string();

export type Rental = z.infer<typeof Rental>;
export const Rental = z.object({
  id: RentalId,
  state: State,
  rental_company_asset_id: RentalCompanyAssetId,
  rental_company_account_id: RentalCompanyAccountId,
  renter_asset_id: RenterAssetId,
  renter_account_id: RenterPartnerAccountId,
  started_at: z.union([RentalStartedAt, z.undefined()]).optional(),
});

export type ContractType = z.infer<typeof ContractType>;
export const ContractType = z.string();

export type RentalCompany = z.infer<typeof RentalCompany>;
export const RentalCompany = z.object({
  id: RentalCompanyAccountId,
  contract_type: ContractType,
});

export type RenterPartnerCompany = z.infer<typeof RenterPartnerCompany>;
export const RenterPartnerCompany = z.object({
  id: RenterPartnerAccountId,
  name: z.string(),
});

export type RentalCompanyList = z.infer<typeof RentalCompanyList>;
export const RentalCompanyList = z.object({
  items: z.array(RentalCompany),
});

export type RenterPartnerList = z.infer<typeof RenterPartnerList>;
export const RenterPartnerList = z.object({
  items: z.array(RenterPartnerCompany),
});

export type RentalList = z.infer<typeof RentalList>;
export const RentalList = z.object({
  items: z.array(Rental),
});

export type StartRentalCommand = z.infer<typeof StartRentalCommand>;
export const StartRentalCommand = z.object({
  rental_company_asset_id: RentalCompanyAssetId,
  renter_account_id: RenterPartnerAccountId,
});

export type StopRentalCommand = z.infer<typeof StopRentalCommand>;
export const StopRentalCommand = z.object({
  rental_id: RentalId,
});

export type StopAndSellCommand = z.infer<typeof StopAndSellCommand>;
export const StopAndSellCommand = z.object({
  rental_id: RentalId,
});

export type ZurueckDoppelCommand = z.infer<typeof ZurueckDoppelCommand>;
export const ZurueckDoppelCommand = z.object({
  rental_company_asset_id: RentalCompanyAssetId,
  renter_account_id: RenterPartnerAccountId,
});

export type get_RentalCompanies = typeof get_RentalCompanies;
export const get_RentalCompanies = {
  method: z.literal("GET"),
  path: z.literal("/rental-companies"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: RentalCompanyList,
};

export type get_RentalCompaniesRentalCompanyAccountId = typeof get_RentalCompaniesRentalCompanyAccountId;
export const get_RentalCompaniesRentalCompanyAccountId = {
  method: z.literal("GET"),
  path: z.literal("/rental-companies/{rental-company-account-id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      "rental-company-account-id": z.string(),
    }),
  }),
  response: RentalCompany,
};

export type get_RentalCompaniesRentalCompanyAccountIdpartners =
  typeof get_RentalCompaniesRentalCompanyAccountIdpartners;
export const get_RentalCompaniesRentalCompanyAccountIdpartners = {
  method: z.literal("GET"),
  path: z.literal("/rental-companies/{rental-company-account-id}/partners"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      "rental-company-account-id": z.string(),
    }),
  }),
  response: RenterPartnerList,
};

export type get_Rentals = typeof get_Rentals;
export const get_Rentals = {
  method: z.literal("GET"),
  path: z.literal("/rentals"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      rental_company_asset_id: z.string().optional(),
      rental_company_account_id: z.string().optional(),
    }),
  }),
  response: RentalList,
};

export type post_CommandsstartRental = typeof post_CommandsstartRental;
export const post_CommandsstartRental = {
  method: z.literal("POST"),
  path: z.literal("/commands/start-rental"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: StartRentalCommand,
  }),
  response: z.object({
    details: z.string().optional(),
    rental: Rental.optional(),
  }),
};

export type post_CommandsstopRental = typeof post_CommandsstopRental;
export const post_CommandsstopRental = {
  method: z.literal("POST"),
  path: z.literal("/commands/stop-rental"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: StopRentalCommand,
  }),
  response: z.object({
    details: z.string().optional(),
    rental: Rental.optional(),
  }),
};

export type post_CommandssellVehicle = typeof post_CommandssellVehicle;
export const post_CommandssellVehicle = {
  method: z.literal("POST"),
  path: z.literal("/commands/sell-vehicle"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: StopAndSellCommand,
  }),
  response: z.object({
    details: z.string().optional(),
    rental: Rental.optional(),
  }),
};

export type post_CommandszurueckDoppel = typeof post_CommandszurueckDoppel;
export const post_CommandszurueckDoppel = {
  method: z.literal("POST"),
  path: z.literal("/commands/zurueck-doppel"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: ZurueckDoppelCommand,
  }),
  response: z.object({
    details: z.string().optional(),
    rental: Rental.optional(),
  }),
};

// <EndpointByMethod>
export const EndpointByMethod = {
  get: {
    "/rental-companies": get_RentalCompanies,
    "/rental-companies/{rental-company-account-id}": get_RentalCompaniesRentalCompanyAccountId,
    "/rental-companies/{rental-company-account-id}/partners": get_RentalCompaniesRentalCompanyAccountIdpartners,
    "/rentals": get_Rentals,
  },
  post: {
    "/commands/start-rental": post_CommandsstartRental,
    "/commands/stop-rental": post_CommandsstopRental,
    "/commands/sell-vehicle": post_CommandssellVehicle,
    "/commands/zurueck-doppel": post_CommandszurueckDoppel,
  },
};
export type EndpointByMethod = typeof EndpointByMethod;
// </EndpointByMethod>

// <EndpointByMethod.Shorthands>
export type GetEndpoints = EndpointByMethod["get"];
export type PostEndpoints = EndpointByMethod["post"];
export type AllEndpoints = EndpointByMethod[keyof EndpointByMethod];
// </EndpointByMethod.Shorthands>

// <ApiClientTypes>
export type EndpointParameters = {
  body?: unknown;
  query?: Record<string, unknown>;
  header?: Record<string, unknown>;
  path?: Record<string, unknown>;
};

export type MutationMethod = "post" | "put" | "patch" | "delete";
export type Method = "get" | "head" | "options" | MutationMethod;

type RequestFormat = "json" | "form-data" | "form-url" | "binary" | "text";

export type DefaultEndpoint = {
  parameters?: EndpointParameters | undefined;
  response: unknown;
};

export type Endpoint<TConfig extends DefaultEndpoint = DefaultEndpoint> = {
  operationId: string;
  method: Method;
  path: string;
  requestFormat: RequestFormat;
  parameters?: TConfig["parameters"];
  meta: {
    alias: string;
    hasParameters: boolean;
    areParametersRequired: boolean;
  };
  response: TConfig["response"];
};

type Fetcher = (
  method: Method,
  url: string,
  parameters?: EndpointParameters | undefined,
) => Promise<Endpoint["response"]>;

type RequiredKeys<T> = {
  [P in keyof T]-?: undefined extends T[P] ? never : P;
}[keyof T];

type MaybeOptionalArg<T> = RequiredKeys<T> extends never ? [config?: T] : [config: T];

// </ApiClientTypes>

// <ApiClient>
export class ApiClient {
  baseUrl: string = "";

  constructor(public fetcher: Fetcher) {}

  setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
    return this;
  }

  // <ApiClient.get>
  get<Path extends keyof GetEndpoints, TEndpoint extends GetEndpoints[Path]>(
    path: Path,
    ...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
  ): Promise<z.infer<TEndpoint["response"]>> {
    return this.fetcher("get", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
  }
  // </ApiClient.get>

  // <ApiClient.post>
  post<Path extends keyof PostEndpoints, TEndpoint extends PostEndpoints[Path]>(
    path: Path,
    ...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
  ): Promise<z.infer<TEndpoint["response"]>> {
    return this.fetcher("post", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
  }
  // </ApiClient.post>
}

export function createApiClient(fetcher: Fetcher, baseUrl?: string) {
  return new ApiClient(fetcher).setBaseUrl(baseUrl ?? "");
}

/**
 Example usage:
 const api = createApiClient((method, url, params) =>
   fetch(url, { method, body: JSON.stringify(params) }).then((res) => res.json()),
 );
 api.get("/users").then((users) => console.log(users));
 api.post("/users", { body: { name: "John" } }).then((user) => console.log(user));
 api.put("/users/:id", { path: { id: 1 }, body: { name: "John" } }).then((user) => console.log(user));
*/

// </ApiClient
