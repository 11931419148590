import { z } from "zod";

export type IdentificationType = z.infer<typeof IdentificationType>;
export const IdentificationType = z.union([z.string(), z.null()]);

export type AssetType = z.infer<typeof AssetType>;
export const AssetType = z.string();

export type AssetStatus = z.infer<typeof AssetStatus>;
export const AssetStatus = z.string();

export type PlugAndChargeId = z.infer<typeof PlugAndChargeId>;
export const PlugAndChargeId = z.object({
  pc_id: z.string(),
});

export type AssetMasterData = z.infer<typeof AssetMasterData>;
export const AssetMasterData = z.object({
  plug_and_charge_ids: z.union([z.array(PlugAndChargeId), z.null()]).optional(),
  cost_center: z.string().optional(),
  customs_bond: z.string().optional(),
  adr: z.string().optional(),
  air_freight_suitability: z.string().optional(),
  fuel_type: z.string().optional(),
  tires: z.string().optional(),
  number_of_axes: z.number().optional(),
  vehicle_model: z.string().optional(),
  engine_type: z.string().optional(),
  comment: z.string().optional(),
  ownership: z
    .object({
      registered_first_at: z.string().optional(),
      ownership_start_at: z.string().optional(),
      ownership_end_at: z.string().optional(),
      type: z.string().optional(),
      reference: z.string().optional(),
      contract_duration_in_months: z.number().optional(),
      contract_rate: z.number().optional(),
    })
    .optional(),
});

export type DeviceType = z.infer<typeof DeviceType>;
export const DeviceType = z.string();

export type Device = z.infer<typeof Device>;
export const Device = z.object({
  id: z.string(),
  type: DeviceType,
  identification: z.string(),
});

export type Tag = z.infer<typeof Tag>;
export const Tag = z.object({
  id: z.string(),
});

export type AssetCreatedOrUpdatedEvent = z.infer<typeof AssetCreatedOrUpdatedEvent>;
export const AssetCreatedOrUpdatedEvent = z.object({
  id: z.string(),
  account_id: z.string(),
  name: z.string(),
  identification: z.union([z.string(), z.null(), z.undefined()]).optional(),
  identification_type: z.union([IdentificationType, z.undefined()]).optional(),
  type: AssetType,
  status: AssetStatus,
  brand: z.union([z.string(), z.null(), z.undefined()]).optional(),
  license_plate: z.union([z.string(), z.null(), z.undefined()]).optional(),
  license_plate_country_code: z.union([z.string(), z.null(), z.undefined()]).optional(),
  master_data: z.union([AssetMasterData, z.undefined()]).optional(),
  associated_devices: z.union([z.array(Device), z.undefined()]).optional(),
  tags: z.union([z.array(Tag), z.undefined()]).optional(),
});

export type AssetDeletedEvent = z.infer<typeof AssetDeletedEvent>;
export const AssetDeletedEvent = z.object({
  id: z.string(),
});

export type AssetEvent = z.infer<typeof AssetEvent>;
export const AssetEvent = z.object({
  id: z.string(),
  type: z.string(),
  timestamp: z.string(),
  payload: z.union([AssetCreatedOrUpdatedEvent, AssetDeletedEvent]),
});

export type Link = z.infer<typeof Link>;
export const Link = z.object({
  href: z.string(),
});

export type AssetEventsPaginationLinks = z.infer<typeof AssetEventsPaginationLinks>;
export const AssetEventsPaginationLinks = z.object({
  self: Link,
  next: Link,
});

export type AssetEventList = z.infer<typeof AssetEventList>;
export const AssetEventList = z.object({
  items: z.array(AssetEvent),
  _links: AssetEventsPaginationLinks,
});

export type Id = z.infer<typeof Id>;
export const Id = z.string();

export type IdString = z.infer<typeof IdString>;
export const IdString = z.string();

export type Asset = z.infer<typeof Asset>;
export const Asset = z.object({
  id: z.string(),
  account_id: z.string(),
  name: z.string(),
  identification: z.union([z.string(), z.null(), z.undefined()]).optional(),
  identification_type: z.union([IdentificationType, z.undefined()]).optional(),
  type: AssetType,
  status: AssetStatus,
  brand: z.union([z.string(), z.null(), z.undefined()]).optional(),
  license_plate: z.union([z.string(), z.null(), z.undefined()]).optional(),
  license_plate_country_code: z.union([z.string(), z.null(), z.undefined()]).optional(),
});

export type TagList = z.infer<typeof TagList>;
export const TagList = z.object({
  items: z.array(Tag),
});

export type AssetEmbedded = z.infer<typeof AssetEmbedded>;
export const AssetEmbedded = z.intersection(
  Asset,
  z.object({
    _embedded: z
      .object({
        tags: TagList.optional(),
        master_data: AssetMasterData.optional(),
      })
      .optional(),
  }),
);

export type PaginationLinks = z.infer<typeof PaginationLinks>;
export const PaginationLinks = z.object({
  self: Link,
  next: z.union([Link, z.undefined()]).optional(),
});

export type AssetEmbeddedList = z.infer<typeof AssetEmbeddedList>;
export const AssetEmbeddedList = z.object({
  items: z.array(AssetEmbedded),
  _links: z.union([PaginationLinks, z.undefined()]).optional(),
});

export type DeviceList = z.infer<typeof DeviceList>;
export const DeviceList = z.object({
  items: z.array(Device),
  _links: z.union([PaginationLinks, z.undefined()]).optional(),
});

export type Association = z.infer<typeof Association>;
export const Association = z.object({
  id: z.string(),
  device_id: z.string(),
  asset_id: z.string(),
});

export type AssociationEmbedded = z.infer<typeof AssociationEmbedded>;
export const AssociationEmbedded = z.intersection(
  Association,
  z.object({
    _embedded: z
      .object({
        device: Device.optional(),
        asset: Asset.optional(),
      })
      .optional(),
  }),
);

export type AssociationEmbeddedList = z.infer<typeof AssociationEmbeddedList>;
export const AssociationEmbeddedList = z.object({
  items: z.array(AssociationEmbedded),
  _links: z.union([PaginationLinks, z.undefined()]).optional(),
});

export type AssetLocation = z.infer<typeof AssetLocation>;
export const AssetLocation = z.string();

export type DeviceLocation = z.infer<typeof DeviceLocation>;
export const DeviceLocation = z.string();

export type AssociationLocation = z.infer<typeof AssociationLocation>;
export const AssociationLocation = z.string();

export type TagLocation = z.infer<typeof TagLocation>;
export const TagLocation = z.string();

export type MasterDataLocation = z.infer<typeof MasterDataLocation>;
export const MasterDataLocation = z.string();

export type Vin = z.infer<typeof Vin>;
export const Vin = z.string();

export type VinDetails = z.infer<typeof VinDetails>;
export const VinDetails = z.object({
  vin: Vin,
  brand: z.string(),
});

export type get_AssetEvents = typeof get_AssetEvents;
export const get_AssetEvents = {
  method: z.literal("GET"),
  path: z.literal("/asset-events"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      after: z.string().optional(),
      limit: z.number().optional(),
    }),
  }),
  response: AssetEventList,
};

export type get_GetAssets = typeof get_GetAssets;
export const get_GetAssets = {
  method: z.literal("GET"),
  path: z.literal("/assets"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      status: z.string().optional(),
      account_id: z.string().optional(),
      identification: z.string().optional(),
      identification_type: z.union([z.string(), z.null()]).optional(),
      type: z.string().optional(),
      license_plate: z.string().optional(),
      embed: z.string().optional(),
      after: z.string().optional(),
      limit: z.number().optional(),
    }),
  }),
  response: AssetEmbeddedList,
};

export type get_GetAsset = typeof get_GetAsset;
export const get_GetAsset = {
  method: z.literal("GET"),
  path: z.literal("/assets/{asset-id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      embed: z.string().optional(),
    }),
    path: z.object({
      "asset-id": z.string(),
    }),
  }),
  response: Asset,
};

export type put_PutAsset = typeof put_PutAsset;
export const put_PutAsset = {
  method: z.literal("PUT"),
  path: z.literal("/assets/{asset-id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      "asset-id": z.string(),
    }),
    body: Asset,
  }),
  response: z.unknown(),
};

export type delete_DeleteAsset = typeof delete_DeleteAsset;
export const delete_DeleteAsset = {
  method: z.literal("DELETE"),
  path: z.literal("/assets/{asset-id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      "asset-id": z.string(),
    }),
  }),
  response: z.unknown(),
};

export type get_GetAssetTags = typeof get_GetAssetTags;
export const get_GetAssetTags = {
  method: z.literal("GET"),
  path: z.literal("/assets/{asset-id}/tags"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      "asset-id": z.string(),
    }),
  }),
  response: TagList,
};

export type get_GetAssetTag = typeof get_GetAssetTag;
export const get_GetAssetTag = {
  method: z.literal("GET"),
  path: z.literal("/assets/{asset-id}/tags/{tag-id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      "asset-id": z.string(),
      "tag-id": z.string(),
    }),
  }),
  response: Tag,
};

export type put_TagAsset = typeof put_TagAsset;
export const put_TagAsset = {
  method: z.literal("PUT"),
  path: z.literal("/assets/{asset-id}/tags/{tag-id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      "asset-id": z.string(),
      "tag-id": z.string(),
    }),
    body: Tag,
  }),
  response: z.unknown(),
};

export type delete_UntagAsset = typeof delete_UntagAsset;
export const delete_UntagAsset = {
  method: z.literal("DELETE"),
  path: z.literal("/assets/{asset-id}/tags/{tag-id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      "asset-id": z.string(),
      "tag-id": z.string(),
    }),
  }),
  response: z.unknown(),
};

export type get_GetMasterData = typeof get_GetMasterData;
export const get_GetMasterData = {
  method: z.literal("GET"),
  path: z.literal("/assets/{asset-id}/masterdata"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      "asset-id": z.string(),
    }),
  }),
  response: AssetMasterData,
};

export type patch_PatchMasterData = typeof patch_PatchMasterData;
export const patch_PatchMasterData = {
  method: z.literal("PATCH"),
  path: z.literal("/assets/{asset-id}/masterdata"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      "asset-id": z.string(),
    }),
    body: AssetMasterData,
  }),
  response: z.unknown(),
};

export type get_GetDevices = typeof get_GetDevices;
export const get_GetDevices = {
  method: z.literal("GET"),
  path: z.literal("/devices"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      device_type: z.string().optional(),
      identification: z.string().optional(),
      orphaned: z.boolean().optional(),
      after: z.string().optional(),
      limit: z.number().optional(),
    }),
  }),
  response: DeviceList,
};

export type get_GetDevice = typeof get_GetDevice;
export const get_GetDevice = {
  method: z.literal("GET"),
  path: z.literal("/devices/{device-id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      "device-id": z.string(),
    }),
  }),
  response: Device,
};

export type put_PutDevice = typeof put_PutDevice;
export const put_PutDevice = {
  method: z.literal("PUT"),
  path: z.literal("/devices/{device-id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      "device-id": z.string(),
    }),
    body: Device,
  }),
  response: z.unknown(),
};

export type delete_DeleteDevice = typeof delete_DeleteDevice;
export const delete_DeleteDevice = {
  method: z.literal("DELETE"),
  path: z.literal("/devices/{device-id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      "device-id": z.string(),
    }),
  }),
  response: z.unknown(),
};

export type get_GetAssociations = typeof get_GetAssociations;
export const get_GetAssociations = {
  method: z.literal("GET"),
  path: z.literal("/associations"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      device_id: z.string().optional(),
      asset_id: z.string().optional(),
      account_id: z.string().optional(),
      embed: z.string().optional(),
      after: z.string().optional(),
      limit: z.number().optional(),
    }),
  }),
  response: AssociationEmbeddedList,
};

export type get_GetAssociation = typeof get_GetAssociation;
export const get_GetAssociation = {
  method: z.literal("GET"),
  path: z.literal("/associations/{association-id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      embed: z.string().optional(),
    }),
    path: z.object({
      "association-id": z.string(),
    }),
  }),
  response: AssociationEmbedded,
};

export type put_CreateAssociation = typeof put_CreateAssociation;
export const put_CreateAssociation = {
  method: z.literal("PUT"),
  path: z.literal("/associations/{association-id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      "association-id": z.string(),
    }),
    body: Association,
  }),
  response: z.unknown(),
};

export type delete_DeleteAssociation = typeof delete_DeleteAssociation;
export const delete_DeleteAssociation = {
  method: z.literal("DELETE"),
  path: z.literal("/associations/{association-id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      "association-id": z.string(),
    }),
  }),
  response: z.unknown(),
};

export type get_GetVinDetails = typeof get_GetVinDetails;
export const get_GetVinDetails = {
  method: z.literal("GET"),
  path: z.literal("/vins/{vin}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      vin: z.string(),
    }),
  }),
  response: VinDetails,
};

// <EndpointByMethod>
export const EndpointByMethod = {
  get: {
    "/asset-events": get_AssetEvents,
    "/assets": get_GetAssets,
    "/assets/{asset-id}": get_GetAsset,
    "/assets/{asset-id}/tags": get_GetAssetTags,
    "/assets/{asset-id}/tags/{tag-id}": get_GetAssetTag,
    "/assets/{asset-id}/masterdata": get_GetMasterData,
    "/devices": get_GetDevices,
    "/devices/{device-id}": get_GetDevice,
    "/associations": get_GetAssociations,
    "/associations/{association-id}": get_GetAssociation,
    "/vins/{vin}": get_GetVinDetails,
  },
  put: {
    "/assets/{asset-id}": put_PutAsset,
    "/assets/{asset-id}/tags/{tag-id}": put_TagAsset,
    "/devices/{device-id}": put_PutDevice,
    "/associations/{association-id}": put_CreateAssociation,
  },
  delete: {
    "/assets/{asset-id}": delete_DeleteAsset,
    "/assets/{asset-id}/tags/{tag-id}": delete_UntagAsset,
    "/devices/{device-id}": delete_DeleteDevice,
    "/associations/{association-id}": delete_DeleteAssociation,
  },
  patch: {
    "/assets/{asset-id}/masterdata": patch_PatchMasterData,
  },
};
export type EndpointByMethod = typeof EndpointByMethod;
// </EndpointByMethod>

// <EndpointByMethod.Shorthands>
export type GetEndpoints = EndpointByMethod["get"];
export type PutEndpoints = EndpointByMethod["put"];
export type DeleteEndpoints = EndpointByMethod["delete"];
export type PatchEndpoints = EndpointByMethod["patch"];
export type AllEndpoints = EndpointByMethod[keyof EndpointByMethod];
// </EndpointByMethod.Shorthands>

// <ApiClientTypes>
export type EndpointParameters = {
  body?: unknown;
  query?: Record<string, unknown>;
  header?: Record<string, unknown>;
  path?: Record<string, unknown>;
};

export type MutationMethod = "post" | "put" | "patch" | "delete";
export type Method = "get" | "head" | "options" | MutationMethod;

type RequestFormat = "json" | "form-data" | "form-url" | "binary" | "text";

export type DefaultEndpoint = {
  parameters?: EndpointParameters | undefined;
  response: unknown;
};

export type Endpoint<TConfig extends DefaultEndpoint = DefaultEndpoint> = {
  operationId: string;
  method: Method;
  path: string;
  requestFormat: RequestFormat;
  parameters?: TConfig["parameters"];
  meta: {
    alias: string;
    hasParameters: boolean;
    areParametersRequired: boolean;
  };
  response: TConfig["response"];
};

type Fetcher = (
  method: Method,
  url: string,
  parameters?: EndpointParameters | undefined,
) => Promise<Endpoint["response"]>;

type RequiredKeys<T> = {
  [P in keyof T]-?: undefined extends T[P] ? never : P;
}[keyof T];

type MaybeOptionalArg<T> = RequiredKeys<T> extends never ? [config?: T] : [config: T];

// </ApiClientTypes>

// <ApiClient>
export class ApiClient {
  baseUrl: string = "";

  constructor(public fetcher: Fetcher) {}

  setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
    return this;
  }

  // <ApiClient.get>
  get<Path extends keyof GetEndpoints, TEndpoint extends GetEndpoints[Path]>(
    path: Path,
    ...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
  ): Promise<z.infer<TEndpoint["response"]>> {
    return this.fetcher("get", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
  }
  // </ApiClient.get>

  // <ApiClient.put>
  put<Path extends keyof PutEndpoints, TEndpoint extends PutEndpoints[Path]>(
    path: Path,
    ...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
  ): Promise<z.infer<TEndpoint["response"]>> {
    return this.fetcher("put", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
  }
  // </ApiClient.put>

  // <ApiClient.delete>
  delete<Path extends keyof DeleteEndpoints, TEndpoint extends DeleteEndpoints[Path]>(
    path: Path,
    ...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
  ): Promise<z.infer<TEndpoint["response"]>> {
    return this.fetcher("delete", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
  }
  // </ApiClient.delete>

  // <ApiClient.patch>
  patch<Path extends keyof PatchEndpoints, TEndpoint extends PatchEndpoints[Path]>(
    path: Path,
    ...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
  ): Promise<z.infer<TEndpoint["response"]>> {
    return this.fetcher("patch", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
  }
  // </ApiClient.patch>
}

export function createApiClient(fetcher: Fetcher, baseUrl?: string) {
  return new ApiClient(fetcher).setBaseUrl(baseUrl ?? "");
}

/**
 Example usage:
 const api = createApiClient((method, url, params) =>
   fetch(url, { method, body: JSON.stringify(params) }).then((res) => res.json()),
 );
 api.get("/users").then((users) => console.log(users));
 api.post("/users", { body: { name: "John" } }).then((user) => console.log(user));
 api.put("/users/:id", { path: { id: 1 }, body: { name: "John" } }).then((user) => console.log(user));
*/

// </ApiClient
