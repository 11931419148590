import { App } from './App';
import { config } from './config';
import { main } from './configuration';
import { handleLoginRedirect } from './configuration/login/redirect';
import type { User } from 'oidc-client-ts';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './configuration/setup/store';

const isDev = import.meta.env.DEV;
const isProd = import.meta.env.PROD;
const isProdPreview = import.meta.env.VITE_PRODUCTION_PREVIEW;

if ((isDev && config.enableMockServer) || isProdPreview) {
    import('../mocks/browser').then(({ startWorker }) => startWorker()).then(() => main(renderApplication));
}

const renderApplication = ({ user }: { user: User | null }) => {
    const container = document.getElementById('root');
    const root = createRoot(container as HTMLElement);
    root.render(
        <Provider store={store}>
            <App user={user} />
        </Provider>
    );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else if (isProd && !isProdPreview) {
    void main(renderApplication);
}
