import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { config } from '../../config';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getIdToken } from '../../configuration/tokenHandling/tokenSlice';
import { ADMINISTRATION_ROUTE } from '../../routes/Router';
import serviceInfo from './ServiceInfo';
import IframeResizer from 'iframe-resizer-react';
import useIsSupportAdministrationRoute from '../../hooks/useIsSupportAdministrationRoute';

const AppHeader = () => {
    const isSupportAdminRoute = useIsSupportAdministrationRoute();

    const idToken = useAppSelector(getIdToken);
    const postLogoutRedirectUri = config.login.postLogoutRedirectUri;

    const navItems = [
        {
            key: 'rental',
            route: (
                <NavLink to={ADMINISTRATION_ROUTE}>
                    <FormattedMessage id={'intl-msg:navigation-bar.sublink.rental'} />
                </NavLink>
            ),
        },
    ];

    const environment = import.meta.env.PROD ? 'production' : 'local';

    const userMenuItem = (
        <DefaultUserMenu
            environment={environment}
            postLogout={
                idToken && postLogoutRedirectUri
                    ? { redirectUri: postLogoutRedirectUri, idTokenHint: idToken }
                    : undefined
            }
            disableCookieModal
        />
    );
    const menuUrl = config.backend.MENU_SERVICE;
    const appNavigator = isSupportAdminRoute ? undefined : (
        <IframeResizer className='iFrameResizer' src={menuUrl} checkOrigin={false} />
    );
    const homeRoute = isSupportAdminRoute ? config.helpGangHomeRoute : config.homeRoute;

    return (
        <ApplicationHeader
            appNavigator={appNavigator}
            label={config.applicationName}
            navItems={navItems}
            actionBarItems={[serviceInfo(), userMenuItem]}
            homeRoute={<a href={homeRoute} />}
        />
    );
};

export default AppHeader;
