import * as api from './generated/assets-api';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../../configuration/tokenHandling/prepareHeaders';
import type { Asset, Device } from '../../domain/model/assets.types';
import { mapAssetToDomain, mapAssociationToDomain } from './assets.mapper';
import { createInfinitePaginationHook } from './pagination.hook';

export const assetsApi = createApi({
    reducerPath: 'assetsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_ASSET_ADMIN_BASE_URL,
        prepareHeaders,
    }),
    endpoints: builder => ({
        fetchAssets: builder.infiniteQuery<{ items: Asset[]; nextLink?: string }, { accountId: string }, string>({
            query: ({ queryArg, pageParam }) => {
                if (pageParam) {
                    return {
                        url: pageParam,
                    };
                }
                return {
                    url: '/assets',
                    params: {
                        account_id: queryArg.accountId,
                    },
                };
            },
            transformResponse: (rawResult: unknown) => {
                const parsed = api.AssetEmbeddedList.parse(rawResult);
                return {
                    items: parsed.items.map(mapAssetToDomain),
                    nextLink: parsed._links?.next?.href,
                };
            },
            infiniteQueryOptions: {
                initialPageParam: '',
                getNextPageParam: lastPage => lastPage.nextLink,
            },
        }),
        fetchAssociations: builder.infiniteQuery<
            { items: { assetId: string; device: Device }[]; nextLink?: string },
            { accountId: string },
            string
        >({
            query: ({ queryArg, pageParam }) => {
                if (pageParam) {
                    return {
                        url: pageParam,
                    };
                }
                return {
                    url: '/associations',
                    params: {
                        account_id: queryArg.accountId,
                        embed: '(device)',
                    },
                };
            },
            transformResponse: (rawResult: unknown) => {
                const parsed = api.AssociationEmbeddedList.parse(rawResult);
                return {
                    items: parsed.items.map(mapAssociationToDomain).filter(it => it !== undefined),
                    nextLink: parsed._links?.next?.href,
                };
            },
            infiniteQueryOptions: {
                initialPageParam: '',
                getNextPageParam: lastPage => lastPage.nextLink,
            },
        }),
    }),
});

export const useAllAssetsQuery = createInfinitePaginationHook(assetsApi.useFetchAssetsInfiniteQuery);
export const useAllAssociationsQuery = createInfinitePaginationHook(assetsApi.useFetchAssociationsInfiniteQuery);
