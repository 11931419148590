import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import { useOnboardingTourContext } from '../../components/OnboardingTour.context';
import { config } from '../../config';
import { FormattedMessage } from 'react-intl';

const ServiceInfo = () => {
    const { setShowOnboardingTour } = useOnboardingTourContext();

    const onShowOnboardingTour = () => {
        setShowOnboardingTour(true);
    };

    const applicationName = config.applicationName;

    const title = (
        <div>
            <span>{applicationName}</span>
        </div>
    );

    return (
        <ActionBarItem id='serviceInfo'>
            <ActionBarItem.Icon>
                <span className='icon rioglyph rioglyph-info-sign' data-testid={'service-info-button'} />
            </ActionBarItem.Icon>
            <ActionBarItem.Popover title={title}>
                <ActionBarItem.List>
                    <ActionBarItem.ListItem icon='rioglyph-question-sign' onClick={onShowOnboardingTour}>
                        <FormattedMessage id='intl-msg:rental-web.service-info.show-onboarding-tour' />
                    </ActionBarItem.ListItem>
                </ActionBarItem.List>
            </ActionBarItem.Popover>
        </ActionBarItem>
    );
};

export default ServiceInfo;
