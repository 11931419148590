export const isHelpGangRoute = () => {
    const initialRoute = [window.location.hash, window.location.search].join('').replace(/^#\/?/u, '');
    return initialRoute?.startsWith('support');
};

export const isRioPlatformLogin = (subject: string): boolean => {
    return subject.startsWith('prod-rio-users:');
};

export const isAzureLogin = (subject: string): boolean => {
    return subject.startsWith('azure-my-rio-de:');
};
