import { createContext, type PropsWithChildren, useContext } from 'react';

type OnboardingTourContextType = {
    showOnboardingTour: boolean;
    setShowOnboardingTour: (show: boolean) => void;
};

export const OnboardingTourContext = createContext<OnboardingTourContextType>(
    {} as unknown as OnboardingTourContextType
);
export const useOnboardingTourContext = () => useContext(OnboardingTourContext);

const OnboardingTourContextProvider = ({
    showOnboardingTour,
    setShowOnboardingTour,
    children,
}: PropsWithChildren<OnboardingTourContextType>) => {
    return (
        <OnboardingTourContext.Provider value={{ showOnboardingTour, setShowOnboardingTour }}>
            {children}
        </OnboardingTourContext.Provider>
    );
};

export default OnboardingTourContextProvider;
