import { createHashRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';

import RouteErrorFallback from '../components/error/RouteErrorFallback';
import AppLayout from '../layout/AppLayout';
import { suspendPageLoad } from './suspendPageLoad';

// Lazy load pages for better performance and automatically split the bundle accordingly
const AdministrationTab = suspendPageLoad(() => import('../pages/AdministrationTab'));
const SupportAdministrationTab = suspendPageLoad(() => import('../pages/SupportAdministrationTab'));

export const ADMINISTRATION_ROUTE = '/administration';
export const SUPPORT_ROUTE_CATCHALL = '/support/*';
export const SUPPORT_ADMINISTRATION_ROUTE = '/support/administration';

export const router = createHashRouter(
    createRoutesFromElements(
        <>
            <Route element={<AppLayout />} errorElement={<RouteErrorFallback />}>
                <Route path={ADMINISTRATION_ROUTE} element={<AdministrationTab />} />
                <Route path={SUPPORT_ADMINISTRATION_ROUTE} element={<SupportAdministrationTab />} />
            </Route>

            <Route path={SUPPORT_ROUTE_CATCHALL} element={<Navigate to={SUPPORT_ADMINISTRATION_ROUTE} />} />
            <Route path='*' element={<Navigate to={ADMINISTRATION_ROUTE} />} />
        </>
    )
);
