/**
 * A generic function to wrap an infiniteQuery hook and auto-fetch more pages
 * until a threshold is met, returning flattened items.
 */
import type { InfiniteData } from '@reduxjs/toolkit/query/react';

const MAX_CALLS = 20;

interface QueryResultPartialProps<TData> {
    data?: InfiniteData<TData, string>;
    isFetching: boolean;
    hasNextPage?: boolean;
    fetchNextPage: () => void;
    // unclear why all extra field need to be specified that should be passed through - it seems the THookResult below only
    // contains the necessary fields from this interface, not more (even if present in the return value of the passed function)
    isLoading: boolean;
    error?: unknown;
    refetch: () => void;
}

interface PageData<TItem> {
    items: TItem[];
    nextLink?: string;
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
type InfiniteQueryFunction<TItem> = (arg: any) => QueryResultPartialProps<PageData<TItem>>;

export function createInfinitePaginationHook<TItem>(useInfiniteQuery: InfiniteQueryFunction<TItem>) {
    type TQueryArg = Parameters<typeof useInfiniteQuery>[0];
    type THookResult = Omit<ReturnType<typeof useInfiniteQuery>, 'data'> & { data: TItem[] };

    return function useGenericInfiniteQuery(props: TQueryArg): THookResult {
        const infiniteQuery = useInfiniteQuery(props);

        if (
            infiniteQuery.hasNextPage &&
            !infiniteQuery.isFetching &&
            (infiniteQuery.data?.pages?.length ?? 0) < MAX_CALLS
        ) {
            console.debug('Fetching additional page ', (infiniteQuery.data?.pages.length ?? 0) + 1, ' of ', MAX_CALLS);
            infiniteQuery.fetchNextPage();
        }

        const flattenedItems = infiniteQuery.data?.pages.flatMap(page => page.items) ?? [];

        return {
            ...infiniteQuery,
            data: flattenedItems as TItem[],
        };
    };
}
