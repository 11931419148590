import { Outlet } from 'react-router-dom';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';

import { isUserSessionExpired } from '../configuration/login/loginSlice';
import { useAppDispatch, useAppSelector } from '../configuration/setup/hooks';
import { getLocale } from '../configuration/lang/langSlice';
import { getSessionExpiredAcknowledged, hideSessionExpiredDialog } from '../data/appSlice';
import AppHeader from '../features/header/AppHeader';

import './App.css';
import { useRef, useState } from 'react';
import { AppContext } from './AppContext';
import OnboardingTourContextProvider from '../components/OnboardingTour.context';

const isInIframe = () => {
    return window.self !== window.top;
};

const AppLayout = () => {
    const dispatch = useAppDispatch();

    const sidebarRef = useRef<HTMLDivElement>(null);

    const isSessionExpired = useAppSelector(isUserSessionExpired);
    const sessionExpiredAcknowledged = useAppSelector(getSessionExpiredAcknowledged);

    const userLocale = useAppSelector(getLocale);
    if (!userLocale) {
        return null;
    }

    const handleSessionExpiredDialogClose = () => dispatch(hideSessionExpiredDialog());
    const showSessionExpired = isSessionExpired && !sessionExpiredAcknowledged;
    const [showOnboardingTour, setShowOnboardingTour] = useState(false);

    return (
        <AppContext.Provider value={{ sidebarRef }}>
            <OnboardingTourContextProvider
                showOnboardingTour={showOnboardingTour}
                setShowOnboardingTour={setShowOnboardingTour}
            >
                <ApplicationLayout data-testid={'app-layout'}>
                    {isInIframe() ? undefined : (
                        <ApplicationLayout.Header>
                            <AppHeader />
                        </ApplicationLayout.Header>
                    )}
                    <ApplicationLayout.Sidebar className="right" ref={sidebarRef} />

                    <ApplicationLayout.Body>
                        <NotificationsContainer />

                        <SessionExpiredDialog
                            locale={userLocale}
                            onClose={handleSessionExpiredDialogClose}
                            show={showSessionExpired}
                        />

                        <Outlet />
                    </ApplicationLayout.Body>
                </ApplicationLayout>
            </OnboardingTourContextProvider>
        </AppContext.Provider>
    );
};

export default AppLayout;
