import type * as api from './generated/assets-api';
import type { Asset, AssetStatus, Association } from '../../domain/model/assets.types';

export const mapAssetToDomain = (asset: api.Asset): Asset => {
    return {
        assetId: asset.id,
        vehicleName: asset.name,
        status: asset.status as AssetStatus,
        type: asset.type,
        vin: asset.identification,
        licensePlate: asset.license_plate,
        licensePlateCountryCode: asset.license_plate_country_code ?? undefined,
    };
};

export const mapAssociationToDomain = (association: api.AssociationEmbedded): Association | undefined => {
    if (!(association._embedded && 'device' in association._embedded && association._embedded.device)) {
        return undefined;
    }
    return {
        assetId: association.asset_id,
        device: {
            type: association._embedded.device.type,
            id: association._embedded.device.id,
            identification: association._embedded.device.identification,
        },
    };
};
