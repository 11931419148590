import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import type { PropsWithChildren } from 'react';
import { config } from '../../config';

export type LoginType = 'azure' | 'rio';

export const InvalidLogin = ({ idToken, loginType }: { idToken: string | undefined; loginType: LoginType }) => {
    let accountType = 'Unknown';
    let proceedToUrl = '';
    let proceedToText = '';
    switch (loginType) {
        case 'azure':
            accountType = 'EntraID';
            proceedToUrl = 'https://helpgang.iam.rio.cloud';
            proceedToText = 'helpgang.iam.rio.cloud';
            break;
        case 'rio':
            accountType = 'RIO Platform';
            proceedToUrl = 'https://home.rio.cloud';
            proceedToText = 'home.rio.cloud';
            break;
        default:
    }

    return (
        <InvalidLoginWrapper>
            <div className={'text-size-h2 text-center margin-bottom-10 text-color-dark'}>Login Error</div>
            <div className={'margin-left-20 margin-right-20 margin-bottom-25 text-center text-color-darker'}>
                You&apos;re logged in with a {accountType} account. Either log out and visit this page again, or proceed
                to
                <br />
                <a href={proceedToUrl}>{proceedToText}</a>.
            </div>
            <div className={'display-flex flex-row justify-content-around'}>
                <a
                    href={
                        idToken && config.login.postLogoutRedirectUri
                            ? `https://auth.iam.rio.cloud/logout?post_logout_redirect_uri=${encodeURIComponent(config.login.postLogoutRedirectUri)}&id_token_hint=${idToken}`
                            : 'https://auth.iam.rio.cloud/logout'
                    }
                    className={'btn btn-default'}
                >
                    Logout
                </a>
                <a href={proceedToUrl} className={'btn btn-primary'}>
                    {proceedToText}
                </a>
            </div>
        </InvalidLoginWrapper>
    );
};

const InvalidLoginWrapper = (props: PropsWithChildren) => (
    <ApplicationLayout>
        <ApplicationLayout.Body>
            <div className={'display-flex justify-content-center height-100pct'}>
                <div className={'width-100pct max-width-400 align-self-center'}>
                    <div className={'panel-default panel panel-body padding-25 flex-column'}>{props.children}</div>
                </div>
            </div>
        </ApplicationLayout.Body>
    </ApplicationLayout>
);
