import { configureStore as configureStoreReduxToolkit } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import appReducer from '../../data/appSlice';
import { rentalApi } from '../../services/api/rental.api';
import { accountsApi } from '../../services/api/accounts.api';
import { assetsApi } from '../../services/api/assets.api';

export const configureStore = () =>
    configureStoreReduxToolkit({
        reducer: {
            config: configReducer,
            lang: langReducer,
            app: appReducer,
            login: loginReducer,
            tokenHandling: tokenReducer,

            // Add the generated reducer as a specific top-level slice
            [accountsApi.reducerPath]: accountsApi.reducer,
            [rentalApi.reducerPath]: rentalApi.reducer,
            [assetsApi.reducerPath]: assetsApi.reducer,
        },

        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware()
                .concat(accountsApi.middleware)
                .concat(rentalApi.middleware)
                .concat(assetsApi.middleware),
    });

export const store = configureStore();

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
