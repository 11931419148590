import * as api from './generated/rental-api';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../../configuration/tokenHandling/prepareHeaders';
import type {
    SellVehicleCommand,
    StartRentalCommand,
    StopRentalCommand,
    ZurueckDoppelCommand,
} from '../../domain/model/commands.types';
import type { RentalCompany, RenterPartnerCompany } from '../../domain/model/rental-companies.types';
import {
    mapRentalCompanyToDomain,
    mapRentalToDomain,
    mapRenterPartnerCompanyToDomain,
    mapSellVehicleCommandToApi,
    mapStartRentalCommandToApi,
    mapStopRentalCommandToApi,
    mapZurueckDoppelCommandToApi,
} from './rental.mapper';
import type { Rental } from '../../domain/model/rentals.types';

const RENTALS_TAG = 'Rentals';

export const rentalApi = createApi({
    reducerPath: 'rentalApi',
    baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_RENTAL_SERVICE_BASE_URL, prepareHeaders }),
    tagTypes: [RENTALS_TAG],
    endpoints: builder => ({
        fetchRentals: builder.query<Rental[], { accountId: string }>({
            query: ({ accountId }) => ({
                url: '/rentals',
                params: {
                    rental_company_account_id: accountId,
                },
            }),
            transformResponse: response => api.RentalList.parse(response).items.map(mapRentalToDomain),
            providesTags: (_, __, { accountId }) => [{ type: RENTALS_TAG, id: accountId }],
        }),
        fetchRentalCompanies: builder.query<RentalCompany[], void>({
            query: () => '/rental-companies',
            transformResponse: response => api.RentalCompanyList.parse(response).items.map(mapRentalCompanyToDomain),
        }),
        fetchRentalCompany: builder.query<RentalCompany, { accountId: string }>({
            query: ({ accountId }) => ({
                url: `/rental-companies/${accountId}`,
            }),
            transformResponse: response => mapRentalCompanyToDomain(api.RentalCompany.parse(response)),
        }),
        fetchRentalCompanyPartners: builder.query<RenterPartnerCompany[], { accountId: string }>({
            query: ({ accountId }) => ({
                url: `/rental-companies/${accountId}/partners`,
            }),
            transformResponse: response =>
                api.RenterPartnerList.parse(response).items.map(mapRenterPartnerCompanyToDomain),
        }),
        startRental: builder.mutation<void, StartRentalCommand>({
            query: command => ({
                url: '/commands/start-rental',
                method: 'POST',
                body: mapStartRentalCommandToApi(command),
            }),
            invalidatesTags: [RENTALS_TAG],
        }),
        stopRental: builder.mutation<void, StopRentalCommand>({
            query: command => ({
                url: '/commands/stop-rental',
                method: 'POST',
                body: mapStopRentalCommandToApi(command),
            }),
            invalidatesTags: [RENTALS_TAG],
        }),
        sellVehicle: builder.mutation<void, SellVehicleCommand>({
            query: command => ({
                url: '/commands/sell-vehicle',
                method: 'POST',
                body: mapSellVehicleCommandToApi(command),
            }),
            invalidatesTags: [RENTALS_TAG],
        }),
        zurueckDoppel: builder.mutation<void, ZurueckDoppelCommand>({
            query: command => ({
                url: '/commands/zurueck-doppel',
                method: 'POST',
                body: mapZurueckDoppelCommandToApi(command),
            }),
            invalidatesTags: [RENTALS_TAG],
        }),
    }),
});

export const {
    useFetchRentalsQuery,
    useLazyFetchRentalsQuery,
    useFetchRentalCompaniesQuery,
    useFetchRentalCompanyQuery,
    useFetchRentalCompanyPartnersQuery,
    useStartRentalMutation,
    useStopRentalMutation,
    useSellVehicleMutation,
    useZurueckDoppelMutation,
} = rentalApi;
