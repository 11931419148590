import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../../configuration/tokenHandling/prepareHeaders';
import type { Account } from '../../domain/model/accounts.types';
import { type AccountApi, mapAccountToDomain } from './accounts.mapper';

export const accountsApi = createApi({
    reducerPath: 'accountApi',
    baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_IAM_API_BASE_URL, prepareHeaders }),
    endpoints: builder => ({
        fetchAccountById: builder.query<Account, string>({
            query: id => `/accounts/${id}`,
            transformResponse: (response: AccountApi) => mapAccountToDomain(response),
        }),
        getAccountsByIdBatch: builder.query<Account[], { accountIds: string[] }>({
            async queryFn({ accountIds }, _queryApi, _extraOptions, baseQuery) {
                const deduplicatedAccountIds = Array.from(new Set(accountIds));
                const chunkSize = 30; // chunkSize is aligned with Team Network
                const chunks = Array.from({ length: Math.ceil(deduplicatedAccountIds.length / chunkSize) }, (_, i) =>
                    deduplicatedAccountIds.slice(i * chunkSize, (i + 1) * chunkSize)
                );

                try {
                    const results = await Promise.all(
                        chunks.map(async chunk => {
                            const queryString = `ids:${chunk.join(',')}`;
                            const result = await baseQuery({
                                url: `/accounts?q=${queryString}`,
                            });

                            if (result.error) {
                                throw new Error(
                                    `Failed to fetch accounts: ${result.error.status} ${JSON.stringify(result.error.data)}`
                                );
                            }

                            return result.data as { items: AccountApi[] };
                        })
                    );

                    const accounts = results.flatMap(response => response.items.map(mapAccountToDomain));

                    return { data: accounts };
                } catch (error: any) {
                    return { error: { status: 'FETCH_ERROR', error: error.message } };
                }
            },
        }),
    }),
});

export const { useFetchAccountByIdQuery, useGetAccountsByIdBatchQuery } = accountsApi;
