import type { Account } from '../../domain/model/accounts.types';

export interface AccountApi {
    id: string;
    name: string;
    tenant: string;
    life_cycle_state: string;
    account_type: string;
}

export const mapAccountToDomain = (account: AccountApi): Account => {
    return {
        id: account.id,
        name: account.name,
    };
};
